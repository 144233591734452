<template>

  <td class="w-5 px-4 py-2">
    <div ref="onlineTooltipTrigger" :class="[getOnlineStatus() == 'Online' ? 'bg-green-500' : 'bg-red-500', 'w-2.5', 'h-2.5', 'rounded-full', 'group ']" aria-hidden="true" />
    <div ref="onlineTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
      {{ getOnlineStatus() }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </td>

  <td class="px-1 py-2 font-medium text-left w-28">
    {{ device.serialNumber }}
  </td>

  <td class="px-1 py-2 font-medium text-left w-28">
    {{ device.owner.name }}
  </td>

  <td class="px-1 py-2 font-medium text-left w-28">
    {{ getDeviceControllerID(device) }}
  </td>

  <td class="w-full px-1 py-2 font-light break-words sm:break-keep sm:w-fit">
    {{ device.configV2.accessories.bracelets.map(bracelet => bracelet.serial_number).join(", ") }}
  </td>

  <td class="px-1 py-2">
    <div class="flex flex-row justify-end w-full gap-2 pr-5">
      <div>
        <DeviceMobileIcon ref="networkTooltipTrigger" :class="['h-6 w-6 rounded-full', ...onlineStatusColor(device), 'px-1']" aria-hidden="true" />
        <div ref="networkTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
          <p v-html="getOnlineStatusOrAlarm()"></p>
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>

      <div>
        <div ref="wifiTooltipTrigger">
          <div class='w-6 h-6 px-1 text-gray-800 bg-gray-100 rounded-full'></div>
        </div>
        <div ref="wifiTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
          {{ getWifiStatusOrAlarm() }}
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>

      <div>
        <BatteryIcon ref="powerSupplyStatusTooltipTrigger" :class="['h-6 w-6 rounded-full', ...powerSupplyStatusColor(device), 'px-1']" aria-hidden="true" />
        <div ref="powerSupplyStatusTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
          {{ getPowerStatusOrAlarm() }}
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>

      <div>
        <div ref="braceletStatusTooltipTrigger">
          <BraceletIcon :class="['h-6 w-6 rounded-full', ...braceletPresenceStatusColor(device), 'px-1']" aria-hidden="true" />
        </div>
        <div ref="braceletStatusTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
          {{ getBraceletStatus() }}
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>

      <div>
        <CalendarIcon ref="dailyCheckInStatusTooltipTrigger" :class="['h-6 w-6 rounded-full', ...dailyCheckInStatusColor(device), 'px-1']" aria-hidden="true" />
        <div ref="dailyCheckInStatusTooltipTarget" class="inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
          {{ getDailyCheckInStatusText() }}
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>

    </div>
  </td>

  <td class="w-16 px-1 py-2 pr-4 text-sm font-light">
    {{ device.firmware ? device.firmware.version : "" }}
  </td>
</template>

<script>
import {
  braceletPresenceStatusColor,
  onlineStatusColor,
  powerSupplyStatusColor,
  dailyCheckInStatusColor,
} from "@/lib/statusColorV23";
import { DeviceMobileIcon, CalendarIcon } from "@heroicons/vue/outline";
import { BatteryIcon } from "@/components/icons";
import { Tooltip } from "flowbite";
import { onMounted, ref } from "vue";
import { computed } from "vue";
import { hasWifiConnection, hasAlert, getHighestDescription } from "@/lib/alarms";
import { useI18n } from "vue-i18n";
import { wifiStatusText, powerSupplyStatusText, dailyCheckInStatusText, braceletPresenceStatusText } from "@/lib/statusTexts";
import { BraceletIcon } from "@/components/icons";


export default {
  methods: {
    hasWifiConnection,
    getHighestDescription
  },
  components: {
    DeviceMobileIcon,
    CalendarIcon,
    BatteryIcon,
    BraceletIcon
  },
  name: "DeviceListItemV23",
  props: {
    device: { required: true },
    isSuperAdmin: { required: true },
  },
  setup(props) {

    const { t } = useI18n();

    const isConnectedWifi = computed(() => hasWifiConnection(props.device))

    const onlineTooltipTrigger = ref(null);
    const onlineTooltipTarget = ref(null);

    const networkTooltipTrigger = ref(null);
    const networkTooltipTarget = ref(null);

    const wifiTooltipTrigger = ref(null);
    const wifiTooltipTarget = ref(null);

    const dailyCheckInStatusTooltipTarget = ref(null);
    const dailyCheckInStatusTooltipTrigger = ref(null);

    const powerSupplyStatusTooltipTrigger = ref(null);
    const powerSupplyStatusTooltipTarget = ref(null);

    const braceletStatusTooltipTrigger = ref(null);
    const braceletStatusTooltipTarget = ref(null);

    onMounted(() => {
      const options = {
        placement: 'bottom',
        triggerType: 'hover',
      }
      new Tooltip(onlineTooltipTarget.value, onlineTooltipTrigger.value, options);

      if (props.device) {
        new Tooltip(networkTooltipTarget.value, networkTooltipTrigger.value, options)
        new Tooltip(wifiTooltipTarget.value, wifiTooltipTrigger.value, options)
        new Tooltip(powerSupplyStatusTooltipTarget.value, powerSupplyStatusTooltipTrigger.value, options);
        new Tooltip(dailyCheckInStatusTooltipTarget.value, dailyCheckInStatusTooltipTrigger.value, options);
        new Tooltip(braceletStatusTooltipTarget.value, braceletStatusTooltipTrigger.value, options);
      }
    })

    const getDeviceControllerID = (device) => {
      return [... new Set(device.configV2.alarm_app.destinations.filter(destination => destination.scaip).map(destination => destination.scaip.controller_id))].join(", ")
    }

    const getAlert = (alert) => {
      if (hasAlert(alert, props.device)) {
        return getHighestDescription(alert, props.device)
      }
      return false;
    }


    const getOnlineStatus = () => {
      if (!props.device) return "Offline"
      if (props.device && props.device.stateSummary.cloudReportedDevicePresence == "online") return "Online"
      return "Offline"
    }

    const getOnlineStatusOrAlarm = () => {
      const alert = getAlert("network")
      if (alert) return alert;

      if (!props.device) return "Offline"

      if (props.device && props.device.stateSummary.cloudReportedDevicePresence == "online") return "Online"

      return "Offline"
    }

    const getWifiStatusOrAlarm = () => {
      if (!isConnectedWifi.value) return t("alarms.wifiNotConnected")
      const alert = getAlert("wifi");
      if (alert) return alert;

      return wifiStatusText(props.device, t)
    }

    const getPowerStatusOrAlarm = () => {
      const alert = getAlert("power")
      if (alert) return alert;
      const status = powerSupplyStatusText(props.device, t)
      if (status == "-") return t("device.tooltip.powerSupplyStatus");
      return status;
    }

    const getBraceletStatus = () => {
      const alert = getAlert("accessories")
      if (alert) return alert;
      const status = braceletPresenceStatusText(props.device, t);
      if (status == "-") return t("device.tooltip.powerSupplyStatus");
      return status;
    }

    const getDailyCheckInStatusText = () => {
      const status = dailyCheckInStatusText(props.device, t)
      if (status == "-") return t("device.tooltip.dailyCheckIn")
      return status;
    }

    const hasOfflineAlert = (device) => {
      if (!hasAlert("network", device)) return false;
      return true;
    }

    return {
      onlineTooltipTrigger,
      onlineTooltipTarget,
      networkTooltipTrigger,
      networkTooltipTarget,
      wifiTooltipTrigger,
      wifiTooltipTarget,
      dailyCheckInStatusTooltipTarget,
      dailyCheckInStatusTooltipTrigger,
      powerSupplyStatusTooltipTarget,
      powerSupplyStatusTooltipTrigger,
      braceletStatusTooltipTarget,
      braceletStatusTooltipTrigger,
      braceletPresenceStatusColor,
      dailyCheckInStatusColor,
      onlineStatusColor,
      isConnectedWifi,
      getDeviceControllerID,
      getOnlineStatusOrAlarm,
      getOnlineStatus,
      getWifiStatusOrAlarm,
      getPowerStatusOrAlarm,
      getDailyCheckInStatusText,
      getBraceletStatus,
      hasOfflineAlert,
      powerSupplyStatusColor
    };
  },
};
</script>
