<template>
  <dl class="flex flex-wrap gap-3 mb-3 md:flex-nowrap ">
    <div class="flex flex-wrap justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown">
      <dt class="my-auto text-sm truncate xl:w-fit lg:w-full">{{ $t("device.header.serialNumber") }}</dt>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-if="device">{{ device.serialNumber }} </dd>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-else>-</dd>
    </div>

    <div class="flex flex-wrap justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown">
      <dt class="my-auto text-sm truncate xl:w-fit lg:w-full">{{ $t("device.header.id") }}</dt>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base">{{ getDeviceId(device) ? getDeviceId(device) : "-" }} </dd>
    </div>

    <div class="flex flex-wrap justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown">
      <dt class="my-auto text-sm truncate xl:w-fit lg:w-full">{{ $t("device.header.firmware") }} </dt>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-if="device.firmware && device.firmware.version">{{ device.firmware.version }} </dd>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-else>-</dd>
    </div>

    <div class="flex flex-wrap justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown">
      <dt class="my-auto mr-2 text-sm xl:w-fit lg:w-full">{{ $t("device.header.tenant") }} </dt>
      <dd class="my-auto text-sm font-medium truncate lg:font-semibold lg:text-base" v-if="device.owner && device.owner.name">{{ device.owner.name }} </dd>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-else>-</dd>
    </div>
  </dl>

  <div class="relative ">
    <dl class="relative top-0 flex flex-wrap justify-between w-full gap-3 ">

      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto justify-between relative h-full',
        ...onlineStatusColor(device),
      ]">
        <div>
          <div class="flex justify-between w-full">
            <div class="justify-between block">
              <dt class="text-sm truncate">{{ $t("device.header.connectivityStatus") }}</dt>
              <div class="pr-5 mr-5 text-sm font-medium lg:font-semibold">
                <span v-if="device.stateSummary.cloudReportedDevicePresence == 'online'" class="truncate">
                  Online via 4G/LTE {{ getConnectionStatus(device) }}
                </span>
                <span v-else>{{ presenceStatusLabel(device) }}</span>
              </div>
            </div>
            <div class="flex items-center flex-shrink-0 ">
              <DeviceMobileIcon class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>

      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full',
        ...powerSupplyStatusColor(device)]">
        <div>
          <div class="flex justify-between w-full">
            <div class="justify-between block w-full sm:flex md:block">
              <dt class="flex text-sm truncate">{{ $t("stateSummary.label.powerSupplyState") }}</dt>
              <dd class="text-sm font-medium lg:font-semibold lg:text-base"> {{ powerSupplyStatusText(device, $t) }} </dd>
            </div>
            <div class="items-center flex-shrink-0 hidden md:flex">
              <BatteryIcon :class="['h-8', 'w-8', 'rounded-full', 'px-1', 'mx-1']" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>

      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full',
        ...braceletPresenceStatusColor(device, 'accessories')]">

        <div>
          <div class="flex justify-between">
            <div class="justify-between block w-full sm:flex md:block">
              <dt class="flex text-sm truncate"> {{ $t("stateSummary.label.braceletPresenceState") }} </dt>
              <dd class="text-sm font-medium lg:font-semibold lg:text-base"> {{ braceletPresenceStatusText(device, $t) }} </dd>
            </div>
            <div class="items-center flex-shrink-0 hidden md:flex">
              <BraceletIcon :class="['h-8', 'w-8', 'rounded-full', 'px-1', 'mx-1']" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>


      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full',
        ...dailyCheckInStatusColor(device),
      ]">
        <div class="flex justify-between ">
          <div class="justify-between block w-full sm:flex md:block">
            <dt class="text-sm truncate">{{ $t("device.general.dailyCheckIn") }}</dt>
            <dd class="text-sm font-medium lg:font-semibold lg:text-base"> {{ dailyCheckInStatusText(device, $t) }} </dd>
          </div>
          <div class="items-center flex-shrink-0 hidden md:flex">
            <CalendarIcon :class="['h-8', 'w-8', 'rounded-full', ...dailyCheckInStatusColor(device), 'px-1']" aria-hidden="true" />
          </div>
        </div>
      </div>
    </dl>

  </div>
</template>

<script>
import {
  powerSupplyStatusColor,
  braceletBatteryStatusColor,
  braceletPresenceStatusColor,
  onlineStatusColor,
  cloudPresenceStatusColor,
  devicePresenceStatusColor,
  dailyCheckInStatusColor,
} from "@/lib/statusColorV23";
import { CalendarIcon, DeviceMobileIcon } from "@heroicons/vue/outline";
import { BraceletIcon } from "@/components/icons";
import { computed } from "vue";
import { useStore } from "vuex";
import { BatteryIcon } from "./icons";
import { useI18n } from "vue-i18n";
import { braceletPresenceStatusText, dailyCheckInStatusText, powerSupplyStatusText } from "@/lib/statusTexts";

export default {
  name: "DeviceStateSummary^",
  methods: {
    braceletPresenceStatusText,
    powerSupplyStatusText,
    onlineStatusColor,
    dailyCheckInStatusColor,
    dailyCheckInStatusText,
    powerSupplyStatusColor,
    braceletBatteryStatusColor,
    braceletPresenceStatusColor,
    cloudPresenceStatusColor,
    devicePresenceStatusColor,
  },
  components: {
    CalendarIcon,
    BraceletIcon,
    BatteryIcon,
    DeviceMobileIcon
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const device = computed(() => store.state.device.current);

    const presenceStatusLabel = (device) => {
      if (device.stateSummary.deviceReportedDevicePresence === "offline_battery_empty") {
        return t("device.errorStatus.emptyBattery");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_low_power_mode") {
        return t("device.errorStatus.lowPowerMode");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_turned_off") {
        return t("device.errorStatus.off");
      }
      if (device.stateSummary.cloudReportedDevicePresence === "offline_unknown") {
        return t("stateSummary.cloudReportedPresence.offlineUnknown");
      }
      return t("device.errorStatus.missingDevice");
    };

    const getDeviceId = (device) => {
      return [... new Set(device.configV2.alarm_app.destinations.filter(destination => destination.scaip).map(destination => destination.scaip.controller_id))].join(", ")
    };

    const getConnectionStatus = (device) => {
      let ret;
      if (device.telemetry && device.telemetry.modem) {
        ret = device.telemetry.modem.operator + "(" + device.telemetry.modem.technology + ")";
      }

      return ret;
    };


    return {
      device,
      presenceStatusLabel,
      getDeviceId,
      getConnectionStatus
    };
  },
};
</script>
